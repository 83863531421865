import AccountGroupResponseModel from "./AccountGroupResponseModel";

export default class AccountGroupRequestModel {
  constructor(
    public accountGroupName = "",
    public description?: string,
    public networkIntegrationId?: number
  ) {}

  static of(model: AccountGroupRequestModel | AccountGroupResponseModel) {
    return new AccountGroupRequestModel(
      model.accountGroupName,
      model.description,
      model.networkIntegrationId
    );
  }
}
