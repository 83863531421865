import AccountResponseModel from "./AccountResponseModel";

export default class AccountRequestModel {
  constructor(public accountName = "", public accountGroupId?: number) {}

  static of(
    model: AccountRequestModel | AccountResponseModel
  ): AccountRequestModel {
    return new AccountRequestModel(model.accountName, model.accountGroupId);
  }
}
