












































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { debounce } from "lodash";

import AccountGroupApplicationRequestModel from "@/settings/models/networks-management/AccountGroupApplicationRequestModel";
import AccountGroupApplicationResponseModel from "@/settings/models/networks-management/AccountGroupApplicationResponseModel";
import { Application } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class ApplicationBindingForm extends Vue {
  @Prop() value!: AccountGroupApplicationRequestModel;
  @Prop() editingItem!: AccountGroupApplicationResponseModel | null;
  @Prop() accountGroupId!: number;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];

  get localValue(): AccountGroupApplicationRequestModel {
    return this.value;
  }

  set localValue(value: AccountGroupApplicationRequestModel) {
    this.$emit("input", value);
  }

  get isApplicationIdUnique(): boolean {
    return this.$store.state.networksManagementStore.isApplicationIdUnique;
  }

  get accountGroupApplications(): Array<AccountGroupApplicationResponseModel> {
    return this.$store.state.networksManagementStore.accountGroupApplications;
  }

  get applications(): Array<{ text: string; value: string }> {
    return this.$store.state.application.apps.map(
      ({ id, name }: Application) => ({
        text: name,
        value: id,
      })
    );
  }

  get aliasNameMessages(): Array<string> {
    if (
      !this.editingItem &&
      this.accountGroupApplications.some(
        ({ applicationId }) => applicationId === this.localValue.applicationId
      ) &&
      !this.localValue.aliasName
    ) {
      // this is condition for creating
      return [this.$lang("validation.required")];
    } else if (
      this.editingItem &&
      this.accountGroupApplications.some(
        ({ applicationId, aliasName, id }) =>
          applicationId === this.localValue.applicationId &&
          !aliasName &&
          id !== this.editingItem?.id
      ) &&
      !this.localValue.aliasName
    ) {
      // this is condition for editing
      return [this.$lang("validation.required")];
    }

    return [];
  }

  get networkApplicationIdMessages(): Array<string> {
    if (
      !this.editingItem &&
      this.accountGroupApplications.some(
        ({ networkApplicationId, applicationId }) =>
          networkApplicationId === this.localValue.networkApplicationId &&
          applicationId === this.localValue.applicationId
      )
    ) {
      // this is condition for creating
      return [this.$lang("validation.unique")];
    } else if (
      this.editingItem &&
      this.localValue.networkApplicationId !==
        this.editingItem?.networkApplicationId &&
      this.accountGroupApplications.some(
        ({ networkApplicationId, applicationId }) =>
          networkApplicationId === this.localValue.networkApplicationId &&
          applicationId === this.localValue.applicationId
      )
    ) {
      // this is condition for editing
      return [this.$lang("validation.unique")];
    }

    return [];
  }

  get applicationIdMessages(): Array<string> {
    if (!this.isApplicationIdUnique) {
      return [
        this.$lang(
          "appAdmin.networksManagement.applicationBindings.applicationIdNotUnique"
        ),
      ];
    }

    return [];
  }

  @Watch("localValue.applicationId")
  watchName = debounce(() => {
    if (this.localValue.applicationId === this.editingItem?.applicationId) {
      this.$store.commit("setApplicationIdUniqueness", true);
      return;
    }

    this.$store.dispatch("checkApplicationIdUniqueness", {
      applicationId: this.localValue.applicationId,
      accountGroupId: this.accountGroupId,
    });
  }, 500);
}
