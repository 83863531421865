



































































import { Component, Prop, Vue } from "vue-property-decorator";

import AccountGroupApplicationResponseModel from "@/settings/models/networks-management/AccountGroupApplicationResponseModel";
import AccountGroupResponseModel from "@/settings/models/networks-management/AccountGroupResponseModel";
import AccountGroupApplicationRequestModel from "@/settings/models/networks-management/AccountGroupApplicationRequestModel";

@Component
export default class ApplicationBindingsTable extends Vue {
  @Prop() currentAccountGroupId!: number;

  readonly headers = [
    {
      text: this.$lang(
        "appAdmin.networksManagement.applicationBindings.application"
      ),
      align: "start",
      value: "applicationId",
    },
    {
      text: this.$lang(
        "appAdmin.networksManagement.applicationBindings.gameId"
      ),
      align: "start",
      value: "networkApplicationId",
    },
    {
      text: this.$lang(
        "appAdmin.networksManagement.applicationBindings.aliasName"
      ),
      value: "aliasName",
    },
    {
      text: this.$lang("appAdmin.networksManagement.actions"),
      align: "end",
      value: "actions",
    },
  ];
  menuTransferActive = false;

  get isLoading(): boolean {
    return this.$store.state.networksManagementStore
      .accountGroupApplicationsIsLoading;
  }

  get accountGroupApplications(): Array<AccountGroupApplicationResponseModel> {
    return this.$store.state.networksManagementStore.accountGroupApplications;
  }

  get accountsGroups(): Array<AccountGroupResponseModel> {
    return this.$store.state.networksManagementStore.accountsGroups.filter(
      ({ id }: AccountGroupResponseModel) => id !== this.currentAccountGroupId
    );
  }

  handleClickByTableRow(item: any) {
    this.$emit("edit", item);
  }

  handleDeletingItem(id: number) {
    this.$emit("delete", id);
  }

  emitTransferToAnotherAccountGroup(
    accountGroupId: number,
    item: AccountGroupApplicationResponseModel
  ) {
    this.$emit("transfer", {
      id: item.id,
      payload: new AccountGroupApplicationRequestModel(
        item.networkIntegrationId,
        accountGroupId,
        item.networkApplicationId,
        item.applicationId,
        item?.aliasName || undefined
      ),
    });
  }
}
