









































































































































import { Component, Vue, Prop } from "vue-property-decorator";

import NetworkSidebarForm from "../NetworkSidebarForm.vue";
import AccountParamForm from "./AccountParamForm.vue";
import AccountResponseModel from "@/settings/models/networks-management/AccountResponseModel";
import AccountParamRequestModel from "@/settings/models/networks-management/AccountParamRequestModel";
import AccountParamResponseModel from "@/settings/models/networks-management/AccountParamResponseModel";
import AccountGroupResponseModel from "@/settings/models/networks-management/AccountGroupResponseModel";
import AccountRequestModel from "@/settings/models/networks-management/AccountRequestModel";

@Component({
  components: {
    NetworkSidebarForm,
    AccountParamForm,
  },
})
export default class AccountsTable extends Vue {
  @Prop() currentAccountGroupId!: number;

  readonly headers = [
    { text: "", value: "data-table-expand" },
    {
      text: this.$lang("appAdmin.networksManagement.accounts.accountName"),
      value: "accountName",
    },
    {
      text: this.$lang("appAdmin.networksManagement.actions"),
      align: "end",
      value: "actions",
      sortable: false,
    },
  ];
  readonly accountsParamsHeader = [
    {
      text: this.$lang("appAdmin.networksManagement.key"),
      value: "key",
    },
    {
      text: this.$lang("appAdmin.networksManagement.paramType"),
      value: "paramType",
    },
    {
      text: this.$lang("appAdmin.networksManagement.value"),
      value: "value",
    },
    {
      text: this.$lang("appAdmin.networksManagement.actions"),
      align: "end",
      value: "actions",
      sortable: false,
    },
  ];
  expanded: Array<AccountResponseModel> = [];
  visible = false;
  localValueParam = new AccountParamRequestModel();
  editingParamId = 0;
  menuTransferActive = false;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get isLoading(): boolean {
    return this.$store.state.networksManagementStore.accountsIsLoading;
  }

  get accountsGroups(): Array<AccountGroupResponseModel> {
    return this.$store.state.networksManagementStore.accountsGroups.filter(
      ({ id }: AccountGroupResponseModel) => id !== this.currentAccountGroupId
    );
  }

  get accounts(): Array<AccountResponseModel> {
    return this.$store.state.networksManagementStore.accounts;
  }

  get accountsParams(): Array<AccountParamResponseModel> {
    return this.$store.state.networksManagementStore.accountsParams;
  }

  get isAccountsParamsLoading(): Array<AccountParamResponseModel> {
    return this.$store.state.networksManagementStore.isAccountsParamsLoading;
  }

  get isSavingAccountParamInProgress(): boolean {
    return this.$store.state.networksManagementStore
      .savingAccountParamInProgress;
  }

  get formTitle(): string {
    return this.$lang(
      `appAdmin.networksManagement.accounts.${
        this.editingParamId ? "editParam" : "newParam"
      }`
    );
  }

  handleClickByAccountsTableRow(item: AccountResponseModel) {
    this.$emit("edit", item);
  }

  updateExpanded(items: Array<AccountResponseModel>) {
    this.$store.commit("clearAccountsParams");

    if (items.length) {
      this.$store.dispatch("loadAccountsParamsByAccountId", items[0].id);
    }
  }

  handleOpenParamForm(accountId: number) {
    this.localValueParam.accountId = accountId;
    this.visible = true;
  }

  emitDeleteAccount(id: number) {
    this.$emit("delete", id);
  }

  handleClickByAccountsParamsTableRow(item: AccountParamResponseModel) {
    this.localValueParam = AccountParamRequestModel.of(item);
    this.editingParamId = item.id;
    this.visible = true;
  }

  handleDeletingParam(id: number) {
    this.$store.dispatch("deleteAccountParam", id);
  }

  async handleSavingParam(closeDialog: () => void) {
    if (this.editingParamId) {
      await this.$store.dispatch("updateAccountParam", {
        id: this.editingParamId,
        payload: this.localValueParam,
      });
    } else {
      await this.$store.dispatch("createAccountParam", this.localValueParam);
    }

    closeDialog();
    this.resetLocalData();
  }

  emitTransferToAnotherAccountGroup(
    accountGroupId: number,
    item: AccountResponseModel
  ) {
    this.$emit("transfer", {
      id: item.id,
      payload: new AccountRequestModel(item.accountName, accountGroupId),
    });
  }

  resetLocalData() {
    this.localValueParam = new AccountParamRequestModel();
    this.editingParamId = 0;
  }
}
