





















import { Component, Vue } from "vue-property-decorator";

import AccountGroupStatisticModel, {
  StatisticExecutionStatus,
} from "@/settings/models/networks-management/AccountGroupStatisticModel";
import DateUtil from "@/shared/utils/DateUtil";

@Component
export default class AccountGroupStatisticsTable extends Vue {
  readonly headers = [
    {
      text: this.$lang(
        "appAdmin.networksManagement.statistics.executionEndDate"
      ),
      align: "start",
      value: "executionEndDate",
    },
    {
      text: this.$lang(
        "appAdmin.networksManagement.statistics.executionStatus"
      ),
      align: "start",
      value: "executionStatus",
    },
    {
      text: this.$lang("appAdmin.networksManagement.statistics.statusCode"),
      align: "end",
      value: "statusCode",
    },
    {
      text: this.$lang("appAdmin.networksManagement.statistics.message"),
      align: "end",
      value: "message",
    },
  ];

  get isLoading(): boolean {
    return this.$store.state.networksManagementStore
      .accountGroupStatisticsIsLoading;
  }

  get accountGroupStatistics(): Array<AccountGroupStatisticModel> {
    return this.$store.state.networksManagementStore.accountGroupStatistics;
  }

  getFormattedExecutionEndDate(executionEndDate: Date | string): string {
    return DateUtil.formatDateTime(executionEndDate);
  }

  getStatusColor(status: StatisticExecutionStatus): string {
    return {
      FAILED: "error",
      SUCCESS: "success",
    }[status];
  }
}
