var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.accountGroupApplications,"loading":_vm.isLoading},on:{"click:row":_vm.handleClickByTableRow},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"value":_vm.menuTransferActive,"transition":"scale-transition","offset-y":"","min-width":"100px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"blur",rawName:"v-blur"}],staticClass:"mr-1",attrs:{"icon":"","small":""},on:{"click":function($event){$event.preventDefault();}}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-move-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$lang( "appAdmin.networksManagement.accounts.transferToAccountGroup" ))+" ")])])]}}],null,true)},[_c('v-list',_vm._l((_vm.accountsGroups),function(accountGroup){return _c('v-list-item',{key:accountGroup.id,attrs:{"link":""},on:{"click":function($event){return _vm.emitTransferToAnotherAccountGroup(accountGroup.id, item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(accountGroup.accountGroupName)+" ")])],1)}),1)],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleDeletingItem(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang("btn.delete")))])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }