


























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { cloneDeep, debounce } from "lodash";

import AccountGroupRequestModel from "@/settings/models/networks-management/AccountGroupRequestModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class AccountGroupForm extends Vue {
  @Prop() value!: AccountGroupRequestModel;
  @Prop() initialName!: string;
  @Prop() networkIntegrationId!: number;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];

  get name(): string {
    return this.value.accountGroupName;
  }

  set name(value: string) {
    const cloned = cloneDeep(this.value);
    cloned.accountGroupName = value;

    this.$emit("input", cloned);
  }

  get description(): string | undefined {
    return this.value.description;
  }

  set description(value: string | undefined) {
    const cloned = cloneDeep(this.value);
    cloned.description = value;

    this.$emit("input", cloned);
  }

  get accountGroupNameIsUnique(): boolean {
    return this.$store.state.networksManagementStore.accountGroupNameIsUnique;
  }

  get nameErrorMessages(): Array<string> {
    return !this.accountGroupNameIsUnique
      ? [this.$lang("shared.errors.notUnique")]
      : [];
  }

  @Watch("name")
  watchName = debounce(() => {
    if (this.name === this.initialName) {
      this.$store.commit("setAccountGroupNameIsUnique", true);
      return;
    }

    this.$store.dispatch("checkAccountGroupNameUniqueness", {
      accountGroupName: this.name,
      networkIntegrationId: this.networkIntegrationId,
    });
  }, 500);
}
