



































































import { Component, Prop, Vue } from "vue-property-decorator";

import NetworkSidebarForm from "../NetworkSidebarForm.vue";
import AccountGroupForm from "../AccountGroupForm.vue";
import AccountGroupResponseModel from "@/settings/models/networks-management/AccountGroupResponseModel";
import AccountGroupRequestModel from "@/settings/models/networks-management/AccountGroupRequestModel";

@Component({
  components: {
    NetworkSidebarForm,
    AccountGroupForm,
  },
})
export default class AccountGroupInfo extends Vue {
  @Prop() networkIntegrationId!: number;

  readonly menuItems: Array<Record<string, any>> = [
    {
      function: this.handleEditingAccountGroup,
      label: this.$lang("btn.edit"),
      icon: "mdi-pencil",
    },
    {
      function: this.emitResync,
      label: this.$lang("btn.resync"),
      icon: "mdi-reload",
    },
    {
      function: this.emitDelete,
      label: this.$lang("btn.delete"),
      icon: "mdi-delete",
      color: "error--text",
    },
  ];
  visible = false;
  localValueAccountGroup = new AccountGroupRequestModel();

  get accountGroup(): AccountGroupResponseModel | null {
    return this.$store.state.networksManagementStore.accountGroup;
  }

  get savingAccountGroupInProgress(): boolean {
    return this.$store.state.networksManagementStore
      .savingAccountGroupInProgress;
  }

  get accountGroupName(): string {
    return this.accountGroup?.accountGroupName ?? "";
  }

  get description(): string {
    return this.accountGroup?.description ?? "";
  }

  handleEditingAccountGroup() {
    this.visible = true;
    this.localValueAccountGroup = AccountGroupRequestModel.of(
      this.accountGroup as AccountGroupResponseModel
    );
  }

  emitResync() {
    this.$emit("resync", (this.accountGroup as AccountGroupResponseModel).id);
  }

  emitDelete() {
    this.$emit("delete", (this.accountGroup as AccountGroupResponseModel).id);
  }

  emitUpdate(closeDialog: () => void) {
    this.$emit("update", {
      id: (this.accountGroup as AccountGroupResponseModel).id,
      payload: this.localValueAccountGroup,
      closeDialog,
    });
  }
}
