











import { Component, Prop, Vue } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import AccountRequestModel from "@/settings/models/networks-management/AccountRequestModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class AccountForm extends Vue {
  @Prop() value!: AccountRequestModel;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];

  get accountName(): string {
    return this.value.accountName;
  }

  set accountName(value: string) {
    const cloned = cloneDeep(this.value);
    cloned.accountName = value;

    this.$emit("input", cloned);
  }
}
