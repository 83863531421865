






































import { Component, Prop, Vue } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import AccountParamRequestModel from "@/settings/models/networks-management/AccountParamRequestModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import { ParamType } from "@/settings/models/networks-management/RequestSpecialParamResponseModel";

@Component
export default class AccountParamForm extends Vue {
  @Prop() value!: AccountParamRequestModel;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly paramTypeItems = [
    ParamType.PLACEHOLDER_ID,
    ParamType.AUTH_TOKEN,
    ParamType.CUSTOM,
    ParamType.PLACEHOLDER_SECOND_ID,
  ];

  get key(): string {
    return this.value.key;
  }

  set key(value: string) {
    const cloned = cloneDeep(this.value);
    cloned.key = value;

    this.$emit("input", cloned);
  }

  get paramType(): ParamType {
    return this.value.paramType;
  }

  set paramType(value: ParamType) {
    const cloned = cloneDeep(this.value);
    cloned.paramType = value;

    this.$emit("input", cloned);
  }

  get paramValue(): string {
    return this.value.value;
  }

  set paramValue(value: string) {
    const cloned = cloneDeep(this.value);
    cloned.value = value;

    this.$emit("input", cloned);
  }
}
