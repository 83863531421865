















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import EntityCard from "@/settings/components/networks-management/EntityCard.vue";
import AccountGroupInfo from "@/settings/components/networks-management/account-group/AccountGroupInfo.vue";
import AccountsTable from "@/settings/components/networks-management/account-group/AccountsTable.vue";
import AccountForm from "@/settings/components/networks-management/account-group/AccountForm.vue";
import ApplicationBindingsTable from "@/settings/components/networks-management/account-group/ApplicationBindingsTable.vue";
import ApplicationBindingForm from "@/settings/components/networks-management/account-group/ApplicationBindingForm.vue";
import AccountGroupStatisticsTable from "@/settings/components/networks-management/account-group/AccountGroupStatisticsTable.vue";
import NetworkIntegrationResponseModel from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import AccountGroupApplicationRequestModel from "@/settings/models/networks-management/AccountGroupApplicationRequestModel";
import AccountRequestModel from "@/settings/models/networks-management/AccountRequestModel";
import AccountGroupRequestModel from "@/settings/models/networks-management/AccountGroupRequestModel";
import AccountResponseModel from "@/settings/models/networks-management/AccountResponseModel";
import AccountGroupApplicationResponseModel from "@/settings/models/networks-management/AccountGroupApplicationResponseModel";

@Component({
  components: {
    AccountGroupInfo,
    EntityCard,
    AccountsTable,
    AccountForm,
    ApplicationBindingsTable,
    ApplicationBindingForm,
    AccountGroupStatisticsTable,
  },
})
export default class AccountGroupView extends Vue {
  @Prop() networkIntegration!: NetworkIntegrationResponseModel;

  accountFormVisible = false;
  localValueAccount = new AccountRequestModel();
  editingAccountId = 0;
  applicationFormVisible = false;
  localValueAccountGroupApplication = new AccountGroupApplicationRequestModel();
  editingApplication: AccountGroupApplicationResponseModel | null = null;

  get isLoading(): boolean {
    return this.$store.state.networksManagementStore.accountGroupIsLoading;
  }

  get accountGroup(): any {
    return this.$store.state.networksManagementStore.accountGroup;
  }

  get accountGroupId(): number {
    return Number(this.$route.params.accountGroupId);
  }

  get accountFormTitle(): string {
    return this.$lang(
      `appAdmin.networksManagement.accounts.${
        this.editingAccountId ? "editAccount" : "newAccount"
      }`
    );
  }

  get applicationFormTitle(): string {
    return this.$lang(
      `appAdmin.networksManagement.applicationBindings.${
        this.editingApplication ? "edit" : "new"
      }`
    );
  }

  get savingAccountInProgress(): boolean {
    return this.$store.state.networksManagementStore.savingAccountInProgress;
  }

  get savingApplicationBindingsInProgress(): boolean {
    return this.$store.state.networksManagementStore
      .savingApplicationBindingsInProgress;
  }

  @Watch("accountGroupId", { immediate: true })
  watchAccountGroupId(value: number) {
    if (value) {
      this.$store.dispatch("loadAccountGroupById", value);
      this.$store.dispatch("loadAccountsByAccountGroupId", value);
      this.$store.dispatch(
        "loadAccountGroupApplicationsByAccountGroupId",
        value
      );
      this.$store.dispatch("loadAccountGroupStatisticsByAccountGroupId", value);
    }
  }

  handleResyncAccountGroup(id: number) {
    this.$store.dispatch("resyncAccountGroup", id);
  }

  async handleDeletingAccountGroup(id: number) {
    await this.$store.dispatch("deleteAccountGroup", id);
    this.$router.push({
      name: "accountsGroups",
    });
  }

  async handleUpdatingAccountGroup({
    id,
    payload,
    closeDialog,
  }: {
    id: number;
    payload: AccountGroupRequestModel;
    closeDialog: () => void;
  }) {
    await this.$store.dispatch("updateAccountGroup", { id, payload });
    closeDialog();
  }

  handleChangeAccountFormVisible(value: boolean) {
    if (!value) {
      this.resetLocalAccountData();
    }

    this.accountFormVisible = value;
  }

  handleEditingAccount(item: AccountResponseModel) {
    this.accountFormVisible = true;
    this.localValueAccount = AccountRequestModel.of(item);
    this.editingAccountId = item.id;
  }

  handleDeletingAccount(id: number) {
    this.$store.dispatch("deleteAccount", id);
  }

  handleTransferAccount({
    id,
    payload,
  }: {
    id: number;
    payload: AccountRequestModel;
  }) {
    this.$store.dispatch("updateAccount", {
      id,
      payload,
      isTransfer: true,
    });
  }

  async handleSavingAccount(closeDialog: () => void) {
    if (this.editingAccountId) {
      await this.$store.dispatch("updateAccount", {
        id: this.editingAccountId,
        payload: this.localValueAccount,
      });
    } else {
      this.localValueAccount.accountGroupId = this.accountGroupId;
      await this.$store.dispatch("createAccount", this.localValueAccount);
    }

    closeDialog();
    this.resetLocalAccountData();
  }

  handleChangeApplicationFormVisible(value: boolean) {
    if (!value) {
      this.resetLocalAccountGroupApplicationData();
    }

    this.applicationFormVisible = value;
  }

  handleEditingAccountGroupApplication(item: any) {
    this.localValueAccountGroupApplication =
      AccountGroupApplicationRequestModel.of(item);
    this.editingApplication = cloneDeep(item);
    this.applicationFormVisible = true;
  }

  handleDeletingAccountGroupApplication(id: any) {
    this.$store.dispatch("deleteAccountGroupApplication", id);
  }

  handleTransferAccountGroupApplication({
    id,
    payload,
  }: {
    id: number;
    payload: AccountGroupApplicationRequestModel;
  }) {
    this.$store.dispatch("updateAccountGroupApplication", {
      id,
      payload,
      isTransfer: true,
    });
  }

  async handleSavingAccountGroupApplication(closeDialog: () => void) {
    if (this.editingApplication) {
      await this.$store.dispatch("updateAccountGroupApplication", {
        id: this.editingApplication.id,
        payload: this.localValueAccountGroupApplication,
      });
    } else {
      this.localValueAccountGroupApplication.accountGroupId =
        this.accountGroupId;
      this.localValueAccountGroupApplication.networkIntegrationId =
        this.networkIntegration.id;
      await this.$store.dispatch(
        "createAccountGroupApplication",
        this.localValueAccountGroupApplication
      );
    }

    closeDialog();
    this.resetLocalAccountGroupApplicationData();
  }

  resetLocalAccountData() {
    this.localValueAccount = new AccountRequestModel();
    this.editingAccountId = 0;
  }

  resetLocalAccountGroupApplicationData() {
    this.localValueAccountGroupApplication =
      new AccountGroupApplicationRequestModel();
    this.editingApplication = null;
  }
}
