import AccountParamResponseModel from "./AccountParamResponseModel";
import { ParamType } from "./RequestSpecialParamResponseModel";

export default class AccountParamRequestModel {
  constructor(
    public paramType = ParamType.PLACEHOLDER_ID,
    public key = "",
    public value = "",
    public accountId?: number
  ) {}

  static of(
    model: AccountParamRequestModel | AccountParamResponseModel
  ): AccountParamRequestModel {
    return new AccountParamRequestModel(
      model.paramType,
      model.key,
      model.value,
      model.accountId
    );
  }
}
